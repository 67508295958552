/* .react-modal-content {
  background-color: rgba(0, 0, 0, 0.75);
  padding: 0;
  position: fixed;
  z-index: 11;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  outline: none;
} */

/* @media (min-width: 600px) { */
.react-modal-content {
  background-color: rgba(0, 0, 0, 0.75);
  padding: 0;
  position: fixed;
  z-index: 11;
  bottom: auto;
  right: auto;
  left: 50%;
  top: 50%;
  border: 0;
  border-radius: 4px;
  min-height: 10rem;
  transform: translate(-50%, -50%);
  min-width: 250;
  width: 80%;
  max-width: 500;
}
/* } */
